import { useTranslation } from 'react-i18next';

import { SUBSCRIPTION_BASE_DISCOUNT } from 'constants/subscriptions';
import { DAYS_PER_THREE_MONTH } from 'constants/periods';

import { getDiscountSubscriptionPeriod } from 'helpers/subscriptions';

import SubscriptionCard from './components/SubscriptionCard';

import { SubscriptionCardsProps } from './types';

const OLD_SUBSCRIPTION_PRICE = '66.65';

const SubscribtionCards = ({
    currentSubscription,
    currencySign,
    discountPrice,
    onChangeSubscription,
}: SubscriptionCardsProps) => {
    const { t } = useTranslation();

    const { period, price } = currentSubscription;

    const nextSubPeriod = getDiscountSubscriptionPeriod(period);
    const subscriptionOldPrice = period > DAYS_PER_THREE_MONTH ? OLD_SUBSCRIPTION_PRICE : price;

    return (
        <>
            <SubscriptionCard
                title={t('subscription.discountOfferModal.currentCard.title')}
                bgColor="text-secondary"
                price={price}
                period={period}
                currencySign={currencySign}
            />
            <SubscriptionCard
                title={t('subscription.discountOfferModal.discountCard.title', {
                    percent: SUBSCRIPTION_BASE_DISCOUNT,
                })}
                bgColor="critical-default"
                price={discountPrice}
                period={nextSubPeriod}
                currencySign={currencySign}
                oldPrice={subscriptionOldPrice}
                changeSubscription={onChangeSubscription}
                isDiscountCard
            />
        </>
    );
};

export default SubscribtionCards;
