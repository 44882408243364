import { createSelector } from 'reselect';

import { PRODUCT_CODES } from 'constants/product';

import { RootReducer } from 'types/store';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

export const selectAllSubscriptions = createSelector(
    selectSubscriptions,
    (subscriptions) => subscriptions.subscriptions
);

export const selectCancellationCandidates = createSelector(
    selectSubscriptions,
    (subscriptions) => subscriptions.cancellationCandidates
);

export const selectIsRequestSent = createSelector(selectSubscriptions, (subscriptions) => subscriptions.isRequestSent);

export const selectMainSubscription = createSelector(selectAllSubscriptions, (subscriptions) =>
    subscriptions.find((subscription) => subscription.product_code === PRODUCT_CODES.MAIN)
);

export const selectCancelReason = (state: RootReducer) => state.subscriptions.cancelReason;
